import React from 'react'

function PrivacyPolicy() {
    return (
        <div style={{ padding: '10px' }}>
            <h1>Privacy Policy</h1>
            <br />
            <p>Effective Date: January 28,2025</p>
            <p>
                CliniPoint™ and Remitone™ values your privacy and is committed
                to protecting your personal and Protected Health Information
                (PHI). This Privacy Policy explains how we collect, use,
                disclose, and safeguard your information when you use our App or
                Website. By using the App or Website, you consent to the
                practices described in this Privacy Policy.
            </p>
            <h2>1. Information We Collect</h2>
            We may collect the following types of information:
            <ol type="A">
                <li>
                    Personally Identifiable Information (PII):
                    <ol type="a">
                        <li>
                            Name, e-mail address, phone number, and other
                            contact information.
                        </li>
                        <li>
                            Login credentials for creating and managing your
                            account.
                        </li>
                    </ol>
                </li>
                <li>
                    Protected Health Information (PHI):
                    <ol type="a">
                        <li>
                            Medical history, health-related data, or other
                            information you choose to provide for use within the
                            App per the Health Insurance Portability and
                            Accountability Act (HIPAA) of 1996.
                        </li>
                    </ol>
                </li>
                <li>
                    Usage Information:
                    <ol type="a">
                        <li>
                            Device and app usage data, such as IP address,
                            browser type, operating system, and user
                            preferences.
                        </li>
                        <li>
                            Analytics on user interaction with the App or
                            Website for improving services
                        </li>
                    </ol>
                </li>
                <li>
                    Location Data:
                    <ol type="a">
                        <li>
                            Approximate location data, if enabled and necessary
                            for app functionality.
                        </li>
                    </ol>
                </li>
                <li>Information from Children Under 13:</li>
                <ol type="a">
                    <li>
                        We may collect PII and PHI from children under 13 only
                        with verifiable parental consent, as required by
                        Children’s Online Privacy Protection Act (COPPA) of
                        1998.
                    </li>
                </ol>
            </ol>
            <h2>2. How We Use Your Information</h2>
            <p>We use and collect information for the following purposes:</p>
            <ul>
                <li>To provide, operate, and maintain the App and Website.</li>
                <li>To improve user experience and app functionality.</li>
                <li>
                    To comply with applicable laws, regulations, and industry
                    standards, including, HIPAA and COPPA
                </li>
                <li>
                    To communicate with you including sending updates or
                    responding to inquiries.
                </li>
                <li>For analytics, research and service improvements </li>
            </ul>
            <h2>3. Sharing Your Information</h2>
            <p>
                We do not sell or rent your personal information. We may share
                your information as follows:
            </p>
            <ul>
                <li>
                    With <b>Third-Party Service Providers:</b> For Services such
                    as data storage, and analytics.
                </li>
                <li>
                    To Comply with <b>Legal Requirements:</b> When required by
                    law or in response to valid legal and security processes.
                </li>
                <li>
                    {' '}
                    <b>With your Consent:</b> As explicitly authorized by you
                    for sharing specific data with third parties.
                </li>
            </ul>
            <p>
                All third-party service providers are contractually obligated to
                protect your information in compliance with HIPAA, COPPA, and
                our internal privacy standards.
            </p>
            <h2>4. Parental Consent for Children Under 13</h2>
            <p>
                For children under 13, we require verifiable parental consent
                before collecting any PII or PHI. Parents have the right to:
            </p>
            <ul>
                <li>Review the information collected about their child.</li>
                <li>Request deletion of their child’s information.</li>
                <li>Withdraw consent for further data collection or use.</li>
            </ul>
            <p>
                Due to federal and state laws, we may be required to retain
                healthcare records for a specific period and may not be able to
                delete certain information. However, we can restrict further use
                of such data or anonymize it to protect your child’s privacy.
                For more information, please contact us.
            </p>
            <h2>5. Data Security </h2>
            <p>
                We implement technical, administrative, and physical safeguards
                to protect your information. These include encryption, access
                controls, and regular audits to ensure compliance with HIPAA,
                COPPA.
            </p>
            <h2>6. Your Rights</h2>
            <p>You have the following rights regarding your data:</p>
            <ul>
                <li>
                    Access: Request a copy of the personal or health information
                    we hold about you.
                </li>
                <li>
                    Correction: Request corrections to inaccurate or incomplete
                    data.
                </li>
                <li>
                    Deletion: Request the deletion of your data, subject to
                    applicable legal or regulatory requirements.
                </li>
                <li>
                    Data Portability: Request a copy of your data in a commonly
                    used format.
                </li>
                <li>
                    Manage Permissions: Adjust your privacy settings and revoke
                    consent where applicable.
                </li>
            </ul>
            <p>
                <b>
                    Correction, Access, and Deletion of Healthcare Information
                </b>
                <br />
                In compliance with HIPAA, parents may request access to or
                corrections of their child’s healthcare data by contacting us at
                [Insert Contact Email]. Requests for corrections will be
                reviewed and addressed in accordance with HIPAA regulations.
            </p>
            <h2>7. Children’s Privacy</h2>
            <p>
                We are committed to protecting the privacy of children under 13.
                We collect PII and PHI from children under 13 only with
                verifiable parental consent in compliance with COPPA. Parents
                can review, delete, or manage their child’s information by
                contacting your provider or us at{' '}
                <a href="mailto:sales@medclaimscompliance.us">
                    sales@medclaimscompliance.us
                </a>
                .
            </p>
            <h2>8. Changes to This Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes
                will be posted with a new “Effective Date.” We encourage you to
                review this Privacy Policy periodically to stay informed about
                our practices.
            </p>
            <h2>9. Contact Us</h2>
            <p>
                <a href="mailto:sales@medclaimscompliance.us">
                    sales@medclaimscompliance.us
                </a>
                <br />
                +1 (512) 473-6473
            </p>
            <h2>10. Accessibility</h2>
            <p>
                This Privacy Policy is available in accessible formats. Please
                contact us if you need assistance accessing this document.
            </p>
        </div>
    )
}

export default PrivacyPolicy
