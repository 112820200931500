import React from 'react'
import { LoaderGFContext } from '../../Components/Loader/LoaderProvider'
import { SnackbarContext } from '../../Components/Snackbar/SnackbarProvider'
import { statusChange } from '../api/taskAPIService'

type props = {
    data: any
    updatedTaskList: any
}
export function useViewTaskStatusUpdate({ data, updatedTaskList }: props) {
    // console.log('status-states', data)

    // const [status, setStatus] = React.useState(
    //     !data?.displayStatus ? data.encounterAllData?.displayStatus : null,
    // )
    const [status, setStatus] = React.useState(
        !data?.displayStatus ? data.encounterAllData?.displayStatus : null,
    )
    const { snackbarSetting } = React.useContext(SnackbarContext)
    const { loaderSetting } = React.useContext(LoaderGFContext)
    React.useEffect(() => {
        if (data?.encounterAllData) {
            setStatus(data.encounterAllData.displayStatus)
        } else {
            setStatus(data?.displayStatus)
        }
    }, [data])

    const saveStatus = async (value: string) => {
        loaderSetting(true)
        const bodyObj = {
            id: data?.id,
            status: value,
        }
        const res: any = await statusChange({
            encounterId: data.encounterId,
            displayStatus: value,
        })
        if (res?.status) {
            loaderSetting(false)
            snackbarSetting(true, res?.message, 'success')
            setStatus(res?.task?.status)
            if (updatedTaskList) {
                updatedTaskList(res?.task)
            }
        } else {
            loaderSetting(false)
            snackbarSetting(true, res?.message, 'error')
        }
    }
    return { status, saveStatus }
}
