export interface CodingReportInitialValuesType {
    MDMComplexity?: string | any
    MDMRisk?: string | any
    MDMData?: string | any
    MDMCPTCode?: string | any
    FTFPT?: string | any
    NFTFPT?: string | any
    TPT?: string | any
    TBCPTCode?: string | any
    ICD10Codes?: Array<any>
    OCPTCodes?: Array<any> | any
    MedicalNecessity?: string | any
}
export interface reviewOfSystemType {
    // [key: string]: string
    GENERAL?: string | any
    HEENT?: string | any
    RESPIRATORY?: string | any
    CARDIOVASCULAR?: string | any
    GASTROINTESTINAL?: string | any
    MUSCULOSKELETAL?: string | any
    SKIN?: string | any
    NEUROLOGIC?: string | any
    PSYCHIATRIC?: string | any
    GENITOURINARY?: string | any
    HEMATOLOGIC?: string | any
    ENDOCRINE?: string | any
    LYMPHATIC?: string | any
}
export interface physicalExamType {
    VITALS?: string
    GENERAL?: string
    HEENT?: string
    NECK?: string
    RESPIRATORY?: string
    CARDIOVASCULAR?: string
    ABDOMEN?: string
    LYMPHATIC?: string
    EXTREMITIES?: string
    SKIN?: string
    PSYCHOLOGICAL?: string
    NEUROLOGIC?: string
    GENITOURINARY?: string
    BREAST?: string
}
export interface initial2CValuesType {
    'CHIEF COMPLAINT'?: string | any
    'HISTORY OF PRESENT ILLNESS'?: string | any
    ALLERGIES?: string | any
    'CURRENT MEDICATIONS'?: string | any
    'MEDICAL HISTORY'?: string | any
    'SURGICAL HISTORY'?: string | any
    'SOCIAL HISTORY'?: string | any
    'FAMILY HISTORY'?: string | any
    'REVIEW OF SYSTEMS'?: reviewOfSystemType
    'PHYSICAL EXAM'?: physicalExamType
    'DIAGNOSTIC TESTS'?: string | any
    LABS?: string | any
    ASSESSMENT?: string | any
    PLAN?: string | any
    'CODING REPORT'?: any
    uploadedNote?: string | any
}

export interface initial2COBGYNValuesType {
    Chief_Complaint?: string | any
    Vitals?: string | any
    Allergies?: string | any
    Medications?: string | any
    Problems?: string | any
    Family_History?: string | any
    Social_History?: socialHistoryType | any
    Surgical_History?: string | any
    GYN_History?: gynHistoryType | any
    Obstetric_History?: ObstetricHistoryType | any
    Genetic_Screening_and_Infection_History?: string | any
    Prenatal_Flowsheet?: PrenatalFlowsheetType | any
    Screening?: string | any
    History_of_Present_Illness?: string | any
    Review_of_Systems?: reviewOfSystemType | any
    Physical_Exam?: physicalExamType | any
    Procedure_Documentation?: string | any
    Assessment_and_Plan?: string | any
    Discussion_Notes?: string | any
    Return_to_Office?: string | any
    'CODING REPORT'?: any
    uploadedNote?: string | any
}
export interface socialHistoryType {
    History_of_domestic_violence?: string | any
    Is_blood_transfusion_acceptable_in_an_emergency?: string | any
    exercise_level?: string | any
    tobacco?: string | any
    Do_you_or_have_you_ever_used_any_other_forms_of_tobacco_or_nicotine?:
        | string
        | any
    Do_you_or_have_you_ever_used_smokeless_tobacco?: string | any
    most_recent_tobacco_screening_date?: string | any
    Do_you_use_any_illegal_illicit_or_recreational_drugs?: string | any
    alcohol_consumption?: string | any
    caffeine_consumption?: string | any
    seat_belts_used_routinely?: string | any
    general_stress_level?: string | any
    Are_you_currently_employed?: string | any
    What_is_your_occupation?: string | any
}
export interface gynHistoryType {
    Date_of_LMP?: string | any
    date_of_last_Pap_Smear?: string | any
    history_of_abnormal_PAP?: string | any
    History_of_Cervical_Dysplasia?: string | any
    Sexually_Active?: string | any
    History_of_Sexually_Transmitted_Infection?: string | any
    Age_at_Menarche?: string | any
    History_of_Endometriosis?: string | any
    History_of_Fibroids?: string | any
    History_of_infertility?: string | any
    History_of_recurrent_ovarian_cysts?: string | any
    History_of_PCOS?: string | any
    History_of_vulvar_dysplasia?: string | any
    Current_Birth_Control_Method?: string | any
}
export interface ObstetricHistoryType {
    Total?: string | any
    Full?: string | any
    Pre?: string | any
    AB_I?: string | any
    AB_S?: string | any
    Ectopics?: string | any
    Multiple?: string | any
    Living?: string | any
}
export interface ObstetricHistoryType {
    Total?: string | any
    Full?: string | any
    Pre?: string | any
    AB_I?: string | any
    AB_S?: string | any
    Ectopics?: string | any
    Multiple?: string | any
    Living?: string | any
}

export interface PrenatalFlowsheetType {
    Wt?: string | any
    BP?: string | any
    Glucose?: string | any
    Protein?: string | any
    FM?: string | any
    FHR?: string | any
    Estimated_Fetal_Weight?: string | any
    Fundus?: string | any
    Cervix_Exam?: string | any
    Pres?: string | any
    PLS?: string | any
    Edema?: string | any
    Ketones?: string | any
    Leukocytes?: string | any
    Blood?: string | any
}

export const rosKeys = [
    'GENERAL',
    'HEENT',
    'RESPIRATORY',
    'CARDIOVASCULAR',
    'GASTROINTESTINAL',
    'MUSCULOSKELETAL',
    'SKIN',
    'NEUROLOGIC',
    'PSYCHIATRIC',
    'GENITOURINARY',
    'HEMATOLOGIC',
    'ENDOCRINE',
    'LYMPHATIC',
]
export const peKeys = [
    'VITALS',
    'GENERAL',
    'HEENT',
    'NECK',
    'RESPIRATORY',
    'CARDIOVASCULAR',
    'ABDOMEN',
    'LYMPHATIC',
    'EXTREMITIES',
    'SKIN',
    'PSYCHOLOGICAL',
    'NEUROLOGIC',
    'GENITOURINARY',
    'BREAST',
]

export const review = {
    GENERAL: '',
    HEENT: '',
    RESPIRATORY: '',
    CARDIOVASCULAR: '',
    GASTROINTESTINAL: '',
    MUSCULOSKELETAL: '',
    SKIN: '',
    NEUROLOGIC: '',
    PSYCHIATRIC: '',
    GENITOURINARY: '',
    HEMATOLOGIC: '',
    ENDOCRINE: '',
    LYMPHATIC: '',
}
export const social = {
    History_of_domestic_violence: '',
    Is_blood_transfusion_acceptable_in_an_emergency: '',
    exercise_level: '',
    tobacco: '',
    Do_you_or_have_you_ever_used_any_other_forms_of_tobacco_or_nicotine: '',
    Do_you_or_have_you_ever_used_smokeless_tobacco: '',
    most_recent_tobacco_screening_date: '',
    Do_you_use_any_illegal_illicit_or_recreational_drugs: '',
    alcohol_consumption: '',
    caffeine_consumption: '',
    seat_belts_used_routinely: '',
    general_stress_level: '',
    Are_you_currently_employed: '',
    What_is_your_occupation: '',
}
export const gyn = {
    Date_of_LMP: '',
    date_of_last_Pap_Smear: '',
    history_of_abnormal_PAP: '',
    History_of_Cervical_Dysplasia: '',
    Sexually_Active: '',
    History_of_Sexually_Transmitted_Infection: '',
    Age_at_Menarche: '',
    History_of_Endometriosis: '',
    History_of_Fibroids: '',
    History_of_infertility: '',
    History_of_recurrent_ovarian_cysts: '',
    History_of_PCOS: '',
    History_of_vulvar_dysplasia: '',
    Current_Birth_Control_Method: '',
}
export const ObH = {
    Total: '',
    Full: '',
    Pre: '',
    AB_I: '',
    AB_S: '',
    Ectopics: '',
    Multiple: '',
    Living: '',
}
export const PF = {
    Wt: '',
    BP: '',
    Glucose: '',
    Protein: '',
    FM: '',
    FHR: '',
    Estimated_Fetal_Weight: '',
    Fundus: '',
    Cervix_Exam: '',
    Pres: '',
    PLS: '',
    Edema: '',
    Ketones: '',
    Leukocytes: '',
    Blood: '',
}
export const PE = {
    VITALS: '',
    GENERAL: '',
    HEENT: '',
    NECK: '',
    RESPIRATORY: '',
    CARDIOVASCULAR: '',
    ABDOMEN: '',
    LYMPHATIC: '',
    EXTREMITIES: '',
    SKIN: '',
    PSYCHOLOGICAL: '',
    NEUROLOGIC: '',
    GENITOURINARY: '',
    BREAST: '',
}
