import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Modal,
} from '@mui/material'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTimer } from 'react-timer-hook'
import { LogoutService } from '../../Services/api/userAPIService'
import { SocketContext } from '../Sockets/Socket'

export function TimeoutProvider({ children }: any) {
    const expire = new Date()
    expire.setSeconds(300)
    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        autoStart: false,
        expiryTimestamp: expire,
        onExpire: () => TimerExpire(),
    })
    const navigate = useNavigate()
    const location = useLocation()
    const timeoutRef = useRef<NodeJS.Timeout>()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => {
        const time = new Date()
        time.setSeconds(time.getSeconds() + 300)
        restart(time)
        setOpen(false)
    }

    const TimerExpire = async () => {
        handleLogout()
        handleClose()
    }
    const { socket } = React.useContext(SocketContext)

    const handleLogout = async () => {
        await LogoutService()
            .then((res: any) => {
                if (res?.status && res?.message) {
                    localStorage.clear()
                    sessionStorage.clear()
                    socket.disconnect()
                    navigate('/')
                    setOpen(false)
                    clearTimeout(timeoutRef.current)
                } else {
                    console.log('error logging out')
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        if (exemptedRoutes.includes(location.pathname)) {
            setOpen(false)
            return
        }
        const handleWindowEvents = () => {
            clearTimeout(timeoutRef.current)

            timeoutRef.current = setTimeout(() => {
                //Perform whatever logic you want in here, clear localStorage and log user out, show a popup modal or just navigate to another page
                const time = new Date()
                time.setSeconds(time.getSeconds() + 300)
                restart(time)
                setOpen(true)
            }, 28500000)
            // }, 1000)
        }

        // listen for specific window events to ensure user is still active
        window.addEventListener('mousemove', handleWindowEvents)
        window.addEventListener('keydown', handleWindowEvents)
        window.addEventListener('click', handleWindowEvents)
        window.addEventListener('scroll', handleWindowEvents)

        handleWindowEvents()

        //cleanup function
        return () => {
            window.removeEventListener('mousemove', handleWindowEvents)
            window.removeEventListener('keydown', handleWindowEvents)
            window.removeEventListener('click', handleWindowEvents)
            window.removeEventListener('scroll', handleWindowEvents)
        }
    }, [navigate, location.pathname])

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{ backdropFilter: 'blur(5px)' }}
            >
                <DialogTitle id="alert-dialog-title">
                    Session Timeout
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Your session is about time expire due to inactivity. If
                        you are still there, please continue with the continue
                        button. If not, you will be logged out automatically.
                    </DialogContentText>
                </DialogContent>
                <div
                    style={{
                        width: '30dvw',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        margin: '0 auto',
                        paddingBottom: '10px',
                    }}
                >
                    <Button variant="contained" onClick={handleClose} autoFocus>
                        Continue
                    </Button>
                    <Button variant="contained" onClick={handleLogout}>
                        Log Off {minutes}:
                        {seconds > 9 ? seconds : '0' + seconds}
                    </Button>
                </div>
            </Dialog>
            {children}
        </div>
    )
}

const exemptedRoutes = ['/404', '/forgot', '/', '/privacypolicy', '']
