import { FC, useRef, useState } from 'react'
import {
    initial2CValuesType,
    reviewOfSystemType,
    physicalExamType,
    rosKeys,
    peKeys,
    review,
    PE,
    initial2COBGYNValuesType,
    social,
    gyn,
    ObH,
    PF,
} from '../../../Coding/CodingInterfaces'
import _ from 'lodash'
import Diff from 'react-stylable-diff'
import styles2 from './ViewProgressNote.module.css'
import Divider from '@mui/material/Divider'

interface ViewProgressNoteProps {
    aIndex?: any
    addendumPN?: any
    progressNote?: any
    cleanPN?: any
    styles?: any
    galenOutput?: any
}
const ViewProgressNote: FC<ViewProgressNoteProps> = (
    props: ViewProgressNoteProps,
) => {
    // const { transcripts, rootref, onScroll, onSelectTranscript, open, onClose, pdfUrl } = useViewTranscript(props);
    // const [numPages, setNumPages] = useState<number | null>(null);

    // const pdfContainerRef = useRef<HTMLDivElement>(null);

    // function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    //   setNumPages(numPages);
    // }

    // function handleContextMenu(event: React.MouseEvent<HTMLDivElement>) {
    //   event.preventDefault(); // Prevent default context menu behavior
    // }
    //   const downloadFile = () => {
    //     if (pdfUrl) {
    //         const a = document.createElement('a');
    //         a.href = pdfUrl;
    //         a.download = transcript.filePathPdf_ChartNote?.split('/')[transcript.filePathPdf_ChartNote?.split('/').length - 1];
    //         a.click();
    //     }
    // };
    // console.log(
    //     'progressNotes',
    //     props.galenOutput ? props.galenOutput['2C'] : null,
    // )

    return {
        ...(props.aIndex == null ? (
            <div>
                {props.cleanPN
                    ? Object.keys(props.cleanPN).map(
                          (pNotesKeys: any, index: number) => {
                              if (
                                  pNotesKeys == 'Social_History' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Social_History,
                                          social,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Social History:</b>
                                              {Object.keys(
                                                  props.progressNote
                                                      .Social_History,
                                              ).map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote
                                                              .Social_History[
                                                              keys
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote
                                                                                  .Social_History[
                                                                                  keys
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  pNotesKeys == 'GYN_History' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.GYN_History,
                                          gyn,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>GYN History:</b>
                                              {Object.keys(
                                                  props.progressNote
                                                      .GYN_History,
                                              ).map(
                                                  (
                                                      peKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote
                                                              .GYN_History[
                                                              peKey
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      peKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {peKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote
                                                                                  .GYN_History[
                                                                                  peKey
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  pNotesKeys == 'Obstetric_History' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Obstetric_History,
                                          ObH,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Obstetric History:</b>
                                              {Object.keys(
                                                  props.progressNote
                                                      .Obstetric_History,
                                              ).map(
                                                  (
                                                      OhKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote
                                                              .Obstetric_History[
                                                              OhKey
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      OhKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {OhKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote
                                                                                  .Obstetric_History[
                                                                                  OhKey
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  pNotesKeys == 'Prenatal_Flowsheet' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Prenatal_Flowsheet,
                                          PF,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Prenatal Flowsheet:</b>
                                              {Object.keys(
                                                  props.progressNote
                                                      .Prenatal_Flowsheet,
                                              ).map(
                                                  (
                                                      pfKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote
                                                              .Prenatal_Flowsheet[
                                                              pfKey
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      pfKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {pfKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote
                                                                                  .Prenatal_Flowsheet[
                                                                                  pfKey
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  pNotesKeys == 'Review_of_Systems' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Review_of_Systems,
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Review of Systems:</b>
                                              {Object.keys(
                                                  props.progressNote
                                                      .Review_of_Systems,
                                              ).map(
                                                  (
                                                      rosKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote
                                                              .Review_of_Systems[
                                                              rosKey
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      rosKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {rosKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote
                                                                                  .Review_of_Systems[
                                                                                  rosKey
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  pNotesKeys == 'Physical_Exam' &&
                                  props.progressNote[pNotesKeys]
                              ) {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Physical_Exam,
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Physical Exam:</b>
                                              {Object.keys(
                                                  props.progressNote
                                                      .Physical_Exam,
                                              ).map(
                                                  (
                                                      peKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote
                                                              .Physical_Exam[
                                                              peKey
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      peKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {peKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote
                                                                                  .Physical_Exam[
                                                                                  peKey
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <Divider
                                                                          variant="middle"
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.divider
                                                                          }
                                                                      />
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (
                                  props.progressNote[
                                      pNotesKeys as keyof typeof props.progressNote
                                  ] != null
                              ) {
                                  if (
                                      props.progressNote[
                                          pNotesKeys as keyof typeof props.progressNote
                                      ]?.length > 0
                                  )
                                      return (
                                          <>
                                              <div
                                                  key={pNotesKeys + index}
                                                  className={
                                                      props.styles?.ReportKeys
                                                  }
                                              >
                                                  {pNotesKeys}:
                                                  <div
                                                      className={
                                                          props.styles?.PNData
                                                      }
                                                  >
                                                      {
                                                          props.progressNote[
                                                              pNotesKeys as keyof typeof props.progressNote
                                                          ]
                                                      }
                                                  </div>
                                                  <Divider
                                                      variant="middle"
                                                      className={
                                                          props.styles?.divider
                                                      }
                                                  />
                                              </div>
                                          </>
                                      )
                              }
                          },
                      )
                    : Object.keys(props.progressNote).map(
                          (pNotesKeys: any, index: number) => {
                              if (pNotesKeys == 'REVIEW OF SYSTEMS') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'REVIEW OF SYSTEMS' as keyof initial2CValuesType
                                          ],
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.progressNote[
                                                              'REVIEW OF SYSTEMS'
                                                          ]![
                                                              keys as keyof reviewOfSystemType
                                                          ]
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote[
                                                                                  'REVIEW OF SYSTEMS'
                                                                              ]![
                                                                                  keys as keyof reviewOfSystemType
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      {index <
                                                                      rosKeys.length ? (
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      ) : (
                                                                          <></>
                                                                      )}
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'PHYSICAL EXAM') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote[
                                              'PHYSICAL EXAM' as keyof initial2CValuesType
                                          ],
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map((peKey, index) => {
                                                  if (
                                                      props.progressNote
                                                          .Physical_Exam
                                                  ) {
                                                      return (
                                                          <div
                                                              key={
                                                                  peKey + index
                                                              }
                                                          >
                                                              <div
                                                                  className={
                                                                      props
                                                                          .styles
                                                                          ?.PNKeys2
                                                                  }
                                                              >
                                                                  {peKey}:
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNData
                                                                      }
                                                                  >
                                                                      {
                                                                          props
                                                                              .progressNote
                                                                              .Physical_Exam
                                                                      }
                                                                  </div>
                                                                  <Divider
                                                                      variant="middle"
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.divider
                                                                      }
                                                                  />
                                                              </div>
                                                          </div>
                                                      )
                                                  }
                                              })}
                                          </div>
                                      )
                              } else if (
                                  props.progressNote[
                                      pNotesKeys as keyof typeof props.progressNote
                                  ] != null
                              ) {
                                  if (
                                      props.progressNote[
                                          pNotesKeys as keyof typeof props.progressNote
                                      ]?.length > 0
                                  )
                                      return (
                                          <>
                                              <div
                                                  key={pNotesKeys + index}
                                                  className={
                                                      props.styles?.ReportKeys
                                                  }
                                              >
                                                  {pNotesKeys}:
                                                  <div
                                                      className={
                                                          props.styles?.PNData
                                                      }
                                                  >
                                                      {
                                                          props.progressNote[
                                                              pNotesKeys as keyof typeof props.progressNote
                                                          ]
                                                      }
                                                  </div>
                                                  <Divider
                                                      variant="middle"
                                                      className={
                                                          props.styles?.divider
                                                      }
                                                  />
                                              </div>
                                          </>
                                      )
                              }
                          },
                      )}
            </div>
        ) : (
            <div>
                {props.cleanPN
                    ? Object.keys(props.cleanPN).map(
                          (pNotesKeys: any, index: number) => {
                              if (pNotesKeys == 'Review_of_Systems') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Review_of_Systems,
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Review_of_Systems
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Review_of_Systems[
                                                                                      keys
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Review_of_Systems[
                                                                                      keys
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Physical_Exam') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Physical_Exam,
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map(
                                                  (
                                                      peKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Physical_Exam ==
                                                              null ||
                                                          props.addendumPN
                                                              .Physical_Exam !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      peKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {peKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {/* <div
                                                                            style={{
                                                                                backgroundColor:
                                                                                    '#fff7b0',
                                                                            }}
                                                                        >
                                                                            {
                                                                                props
                                                                                    .progressNote[
                                                                                    'PHYSICAL EXAM'
                                                                                ]![
                                                                                    peKey as keyof physicalExamType
                                                                                ]
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {
                                                                                props
                                                                                    .addendumPN[
                                                                                    'PHYSICAL EXAM'
                                                                                ]![
                                                                                    peKey as keyof physicalExamType
                                                                                ]
                                                                            }
                                                                        </div> */}
                                                                          {/* <ReactDiffViewer
                                                                            oldValue={
                                                                                props
                                                                                    .progressNote[
                                                                                    'PHYSICAL EXAM'
                                                                                ]![
                                                                                    peKey as keyof physicalExamType
                                                                                ]
                                                                            }
                                                                            newValue={
                                                                                props
                                                                                    .addendumPN[
                                                                                    'PHYSICAL EXAM'
                                                                                ]![
                                                                                    peKey as keyof physicalExamType
                                                                                ]
                                                                            }
                                                                            compareMethod={
                                                                                DiffMethod.WORDS
                                                                            }
                                                                            splitView={
                                                                                false
                                                                            }
                                                                            hideLineNumbers
                                                                        /> */}
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              //   inputA={
                                                                              //       'Patient appears uncomfortable, but cooperative. The patient reports an abscess on the Bartholian gland which is causing the discomfort and swelling'
                                                                              //   }
                                                                              //   inputB={
                                                                              //       'Lab culture sent to the lab for further analysis. Antibiotic started as prescribed by the doctor. Follow-up appointment in one week to monitor progress'
                                                                              //   }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Physical_Exam[
                                                                                      peKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Physical_Exam[
                                                                                      peKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'GYN_History') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.GYN_History,
                                          gyn,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>GYN History:</b>
                                              {peKeys.map(
                                                  (
                                                      gynKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .GYN_History ==
                                                              null ||
                                                          props.addendumPN
                                                              .GYN_History != ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      gynKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {gynKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .GYN_History[
                                                                                      gynKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .GYN_History[
                                                                                      gynKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Obstetric_History') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Obstetric_History,
                                          ObH,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Obstetric History:</b>
                                              {peKeys.map(
                                                  (
                                                      ObHKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Obstetric_History ==
                                                              null ||
                                                          props.addendumPN
                                                              .Obstetric_History !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      ObHKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {ObHKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Obstetric_History[
                                                                                      ObHKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Obstetric_History[
                                                                                      ObHKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Prenatal_Flowsheet') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Prenatal_Flowsheet,
                                          PF,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Prenatal Flowsheet:</b>
                                              {peKeys.map(
                                                  (
                                                      PFKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Prenatal_Flowsheet ==
                                                              null ||
                                                          props.addendumPN
                                                              .Prenatal_Flowsheet !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      PFKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {PFKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Prenatal_Flowsheet[
                                                                                      PFKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Prenatal_Flowsheet[
                                                                                      PFKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Social_History') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Social_History,
                                          social,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Social History:</b>
                                              {peKeys.map(
                                                  (
                                                      SHKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Social_History ==
                                                              null ||
                                                          props.addendumPN
                                                              .Social_History !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      SHKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {SHKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Social_History[
                                                                                      SHKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Social_History[
                                                                                      SHKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (props.addendumPN[pNotesKeys] != null) {
                                  return (
                                      <div
                                          key={pNotesKeys + index}
                                          className={props.styles?.ReportKeys}
                                      >
                                          {pNotesKeys}:
                                          <div className={props.styles?.PNData}>
                                              <Diff
                                                  className={styles2.Difference}
                                                  inputA={
                                                      props.progressNote[
                                                          pNotesKeys
                                                      ]
                                                  }
                                                  inputB={
                                                      props.addendumPN[
                                                          pNotesKeys
                                                      ]
                                                  }
                                                  type="words"
                                              />
                                              <Divider
                                                  variant="middle"
                                                  className={
                                                      props.styles?.divider
                                                  }
                                              />
                                          </div>
                                      </div>
                                  )
                              }
                          },
                      )
                    : Object.keys(props.progressNote).map(
                          (pNotesKeys: any, index: number) => {
                              if (pNotesKeys == 'Review_of_Systems') {
                                  if (
                                      !_.isEqual(
                                          props.progressNote.Review_of_Systems,
                                          review,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>REVIEW OF SYSTEMS:</b>
                                              {rosKeys.map(
                                                  (
                                                      keys: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Review_of_Systems
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      keys +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {keys}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Review_of_Systems[
                                                                                      keys
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Review_of_Systems[
                                                                                      keys
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Physical_Exam') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Physical_Exam,
                                          PE,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>PHYSICAL EXAM:</b>
                                              {peKeys.map(
                                                  (
                                                      peKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Physical_Exam ==
                                                              null ||
                                                          props.addendumPN
                                                              .Physical_Exam !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      peKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {peKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          {/* <div
                                                                          style={{
                                                                              backgroundColor:
                                                                                  '#fff7b0',
                                                                          }}
                                                                      >
                                                                          {
                                                                              props
                                                                                  .progressNote[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                      </div>
                                                                      <div>
                                                                          {
                                                                              props
                                                                                  .addendumPN[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                      </div> */}
                                                                          {/* <ReactDiffViewer
                                                                          oldValue={
                                                                              props
                                                                                  .progressNote[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                          newValue={
                                                                              props
                                                                                  .addendumPN[
                                                                                  'PHYSICAL EXAM'
                                                                              ]![
                                                                                  peKey as keyof physicalExamType
                                                                              ]
                                                                          }
                                                                          compareMethod={
                                                                              DiffMethod.WORDS
                                                                          }
                                                                          splitView={
                                                                              false
                                                                          }
                                                                          hideLineNumbers
                                                                      /> */}
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              //   inputA={
                                                                              //       'Patient appears uncomfortable, but cooperative. The patient reports an abscess on the Bartholian gland which is causing the discomfort and swelling'
                                                                              //   }
                                                                              //   inputB={
                                                                              //       'Lab culture sent to the lab for further analysis. Antibiotic started as prescribed by the doctor. Follow-up appointment in one week to monitor progress'
                                                                              //   }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Physical_Exam[
                                                                                      peKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Physical_Exam[
                                                                                      peKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'GYN_History') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.GYN_History,
                                          gyn,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>GYN History:</b>
                                              {peKeys.map(
                                                  (
                                                      gynKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .GYN_History ==
                                                              null ||
                                                          props.addendumPN
                                                              .GYN_History != ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      gynKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {gynKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .GYN_History[
                                                                                      gynKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .GYN_History[
                                                                                      gynKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Obstetric_History') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Obstetric_History,
                                          ObH,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Obstetric History:</b>
                                              {peKeys.map(
                                                  (
                                                      ObHKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Obstetric_History ==
                                                              null ||
                                                          props.addendumPN
                                                              .Obstetric_History !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      ObHKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {ObHKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Obstetric_History[
                                                                                      ObHKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Obstetric_History[
                                                                                      ObHKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Prenatal_Flowsheet') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Prenatal_Flowsheet,
                                          PF,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Prenatal Flowsheet:</b>
                                              {peKeys.map(
                                                  (
                                                      PFKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Prenatal_Flowsheet ==
                                                              null ||
                                                          props.addendumPN
                                                              .Prenatal_Flowsheet !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      PFKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {PFKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Prenatal_Flowsheet[
                                                                                      PFKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Prenatal_Flowsheet[
                                                                                      PFKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (pNotesKeys == 'Social_History') {
                                  if (
                                      !_.isEqual(
                                          props.addendumPN.Social_History,
                                          social,
                                      )
                                  )
                                      return (
                                          <div key={pNotesKeys + index}>
                                              <b>Social History:</b>
                                              {peKeys.map(
                                                  (
                                                      SHKey: string,
                                                      index: number,
                                                  ) => {
                                                      if (
                                                          props.addendumPN
                                                              .Social_History ==
                                                              null ||
                                                          props.addendumPN
                                                              .Social_History !=
                                                              ''
                                                      ) {
                                                          return (
                                                              <div
                                                                  key={
                                                                      SHKey +
                                                                      index
                                                                  }
                                                              >
                                                                  <div
                                                                      className={
                                                                          props
                                                                              .styles
                                                                              ?.PNKeys2
                                                                      }
                                                                  >
                                                                      {SHKey}:
                                                                      <div
                                                                          className={
                                                                              props
                                                                                  .styles
                                                                                  ?.PNData
                                                                          }
                                                                      >
                                                                          <Diff
                                                                              className={
                                                                                  styles2.Difference
                                                                              }
                                                                              inputA={
                                                                                  props
                                                                                      .progressNote
                                                                                      .Social_History[
                                                                                      SHKey
                                                                                  ]
                                                                              }
                                                                              inputB={
                                                                                  props
                                                                                      .addendumPN
                                                                                      .Social_History[
                                                                                      SHKey
                                                                                  ]
                                                                              }
                                                                              type="words"
                                                                          />
                                                                          <Divider
                                                                              variant="middle"
                                                                              className={
                                                                                  props
                                                                                      .styles
                                                                                      ?.divider
                                                                              }
                                                                          />
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          )
                                                      }
                                                  },
                                              )}
                                          </div>
                                      )
                              } else if (props.addendumPN[pNotesKeys] != null) {
                                  return (
                                      <div
                                          key={pNotesKeys + index}
                                          className={props.styles?.ReportKeys}
                                      >
                                          {pNotesKeys}:
                                          <div className={props.styles?.PNData}>
                                              <Diff
                                                  className={styles2.Difference}
                                                  inputA={
                                                      props.progressNote[
                                                          pNotesKeys
                                                      ]
                                                  }
                                                  inputB={
                                                      props.addendumPN[
                                                          pNotesKeys
                                                      ]
                                                  }
                                                  type="words"
                                              />
                                              <Divider
                                                  variant="middle"
                                                  className={
                                                      props.styles?.divider
                                                  }
                                              />
                                          </div>
                                      </div>
                                  )
                              }
                          },
                      )}
            </div>
        )),
    }
    // return {
    //     ...(props.aIndex == null ? (
    //         <div>
    //             {props.cleanPN
    //                 ? Object.keys(props.cleanPN).map(
    //                       (pNotesKeys: any, index: number) => {
    //                           if (
    //                               pNotesKeys == 'REVIEW OF SYSTEMS' &&
    //                               props.progressNote[pNotesKeys]
    //                           ) {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.progressNote[
    //                                           'REVIEW OF SYSTEMS' as keyof initial2CValuesType
    //                                       ],
    //                                       review,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>REVIEW OF SYSTEMS:</b>
    //                                           {rosKeys?.map(
    //                                               (
    //                                                   keys: string,
    //                                                   index: number,
    //                                               ) => {
    //                                                   if (
    //                                                       props.progressNote[
    //                                                           'REVIEW OF SYSTEMS'
    //                                                       ]![
    //                                                           keys as keyof reviewOfSystemType
    //                                                       ]
    //                                                   ) {
    //                                                       return (
    //                                                           <div
    //                                                               key={
    //                                                                   keys +
    //                                                                   index
    //                                                               }
    //                                                           >
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNKeys2
    //                                                                   }
    //                                                               >
    //                                                                   {keys}:
    //                                                                   <div
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.PNData
    //                                                                       }
    //                                                                   >
    //                                                                       {
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'REVIEW OF SYSTEMS'
    //                                                                           ]![
    //                                                                               keys as keyof reviewOfSystemType
    //                                                                           ]
    //                                                                       }
    //                                                                   </div>
    //                                                                   <Divider
    //                                                                       variant="middle"
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.divider
    //                                                                       }
    //                                                                   />
    //                                                               </div>
    //                                                           </div>
    //                                                       )
    //                                                   }
    //                                               },
    //                                           )}
    //                                       </div>
    //                                   )
    //                           } else if (
    //                               pNotesKeys == 'PHYSICAL EXAM' &&
    //                               props.progressNote[pNotesKeys]
    //                           ) {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.progressNote[
    //                                           'PHYSICAL EXAM' as keyof initial2CValuesType
    //                                       ],
    //                                       PE,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>PHYSICAL EXAM:</b>
    //                                           {peKeys.map((peKey, index) => {
    //                                               if (
    //                                                   props.progressNote[
    //                                                       'PHYSICAL EXAM'
    //                                                   ]![
    //                                                       peKey as keyof physicalExamType
    //                                                   ]
    //                                               ) {
    //                                                   return (
    //                                                       <div
    //                                                           key={
    //                                                               peKey + index
    //                                                           }
    //                                                       >
    //                                                           <div
    //                                                               className={
    //                                                                   props
    //                                                                       .styles
    //                                                                       ?.PNKeys2
    //                                                               }
    //                                                           >
    //                                                               {peKey}:
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNData
    //                                                                   }
    //                                                               >
    //                                                                   {
    //                                                                       props
    //                                                                           .progressNote[
    //                                                                           'PHYSICAL EXAM'
    //                                                                       ]![
    //                                                                           peKey as keyof physicalExamType
    //                                                                       ]
    //                                                                   }
    //                                                               </div>
    //                                                               <Divider
    //                                                                   variant="middle"
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.divider
    //                                                                   }
    //                                                               />
    //                                                           </div>
    //                                                       </div>
    //                                                   )
    //                                               }
    //                                           })}
    //                                       </div>
    //                                   )
    //                           } else if (
    //                               props.progressNote[
    //                                   pNotesKeys as keyof typeof props.progressNote
    //                               ] != null
    //                           ) {
    //                               if (
    //                                   props.progressNote[
    //                                       pNotesKeys as keyof typeof props.progressNote
    //                                   ]?.length > 0
    //                               )
    //                                   return (
    //                                       <>
    //                                           <div
    //                                               key={pNotesKeys + index}
    //                                               className={
    //                                                   props.styles?.ReportKeys
    //                                               }
    //                                           >
    //                                               {pNotesKeys}:
    //                                               <div
    //                                                   className={
    //                                                       props.styles?.PNData
    //                                                   }
    //                                               >
    //                                                   {
    //                                                       props.progressNote[
    //                                                           pNotesKeys as keyof typeof props.progressNote
    //                                                       ]
    //                                                   }
    //                                               </div>
    //                                               <Divider
    //                                                   variant="middle"
    //                                                   className={
    //                                                       props.styles?.divider
    //                                                   }
    //                                               />
    //                                           </div>
    //                                       </>
    //                                   )
    //                           }
    //                       },
    //                   )
    //                 : Object.keys(props.progressNote).map(
    //                       (pNotesKeys: any, index: number) => {
    //                           if (pNotesKeys == 'REVIEW OF SYSTEMS') {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.progressNote[
    //                                           'REVIEW OF SYSTEMS' as keyof initial2CValuesType
    //                                       ],
    //                                       review,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>REVIEW OF SYSTEMS:</b>
    //                                           {rosKeys.map(
    //                                               (
    //                                                   keys: string,
    //                                                   index: number,
    //                                               ) => {
    //                                                   if (
    //                                                       props.progressNote[
    //                                                           'REVIEW OF SYSTEMS'
    //                                                       ]![
    //                                                           keys as keyof reviewOfSystemType
    //                                                       ]
    //                                                   ) {
    //                                                       return (
    //                                                           <div
    //                                                               key={
    //                                                                   keys +
    //                                                                   index
    //                                                               }
    //                                                           >
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNKeys2
    //                                                                   }
    //                                                               >
    //                                                                   {keys}:
    //                                                                   <div
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.PNData
    //                                                                       }
    //                                                                   >
    //                                                                       {
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'REVIEW OF SYSTEMS'
    //                                                                           ]![
    //                                                                               keys as keyof reviewOfSystemType
    //                                                                           ]
    //                                                                       }
    //                                                                   </div>
    //                                                                   {index <
    //                                                                   rosKeys.length ? (
    //                                                                       <Divider
    //                                                                           variant="middle"
    //                                                                           className={
    //                                                                               props
    //                                                                                   .styles
    //                                                                                   ?.divider
    //                                                                           }
    //                                                                       />
    //                                                                   ) : (
    //                                                                       <></>
    //                                                                   )}
    //                                                               </div>
    //                                                           </div>
    //                                                       )
    //                                                   }
    //                                               },
    //                                           )}
    //                                       </div>
    //                                   )
    //                           } else if (pNotesKeys == 'PHYSICAL EXAM') {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.progressNote[
    //                                           'PHYSICAL EXAM' as keyof initial2CValuesType
    //                                       ],
    //                                       PE,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>PHYSICAL EXAM:</b>
    //                                           {peKeys.map((peKey, index) => {
    //                                               if (
    //                                                   props.progressNote[
    //                                                       'PHYSICAL EXAM'
    //                                                   ]![
    //                                                       peKey as keyof physicalExamType
    //                                                   ]
    //                                               ) {
    //                                                   return (
    //                                                       <div
    //                                                           key={
    //                                                               peKey + index
    //                                                           }
    //                                                       >
    //                                                           <div
    //                                                               className={
    //                                                                   props
    //                                                                       .styles
    //                                                                       ?.PNKeys2
    //                                                               }
    //                                                           >
    //                                                               {peKey}:
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNData
    //                                                                   }
    //                                                               >
    //                                                                   {
    //                                                                       props
    //                                                                           .progressNote[
    //                                                                           'PHYSICAL EXAM'
    //                                                                       ]![
    //                                                                           peKey as keyof physicalExamType
    //                                                                       ]
    //                                                                   }
    //                                                               </div>
    //                                                               <Divider
    //                                                                   variant="middle"
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.divider
    //                                                                   }
    //                                                               />
    //                                                           </div>
    //                                                       </div>
    //                                                   )
    //                                               }
    //                                           })}
    //                                       </div>
    //                                   )
    //                           } else if (
    //                               props.progressNote[
    //                                   pNotesKeys as keyof typeof props.progressNote
    //                               ] != null
    //                           ) {
    //                               if (
    //                                   props.progressNote[
    //                                       pNotesKeys as keyof typeof props.progressNote
    //                                   ]?.length > 0
    //                               )
    //                                   return (
    //                                       <>
    //                                           <div
    //                                               key={pNotesKeys + index}
    //                                               className={
    //                                                   props.styles?.ReportKeys
    //                                               }
    //                                           >
    //                                               {pNotesKeys}:
    //                                               <div
    //                                                   className={
    //                                                       props.styles?.PNData
    //                                                   }
    //                                               >
    //                                                   {
    //                                                       props.progressNote[
    //                                                           pNotesKeys as keyof typeof props.progressNote
    //                                                       ]
    //                                                   }
    //                                               </div>
    //                                               <Divider
    //                                                   variant="middle"
    //                                                   className={
    //                                                       props.styles?.divider
    //                                                   }
    //                                               />
    //                                           </div>
    //                                       </>
    //                                   )
    //                           }
    //                       },
    //                   )}
    //         </div>
    //     ) : (
    //         <div>
    //             {props.cleanPN
    //                 ? Object.keys(props.cleanPN).map(
    //                       (pNotesKeys: any, index: number) => {
    //                           if (pNotesKeys == 'REVIEW OF SYSTEMS') {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.progressNote[
    //                                           'REVIEW OF SYSTEMS' as keyof initial2CValuesType
    //                                       ],
    //                                       review,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>REVIEW OF SYSTEMS:</b>
    //                                           {rosKeys.map(
    //                                               (
    //                                                   keys: string,
    //                                                   index: number,
    //                                               ) => {
    //                                                   if (
    //                                                       props.addendumPN[
    //                                                           'REVIEW OF SYSTEMS'
    //                                                       ]![
    //                                                           keys as keyof reviewOfSystemType
    //                                                       ]
    //                                                   ) {
    //                                                       return (
    //                                                           <div
    //                                                               key={
    //                                                                   keys +
    //                                                                   index
    //                                                               }
    //                                                           >
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNKeys2
    //                                                                   }
    //                                                               >
    //                                                                   {keys}:
    //                                                                   <div
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.PNData
    //                                                                       }
    //                                                                   >
    //                                                                       {/* <div
    //                                                                           style={{
    //                                                                               backgroundColor:
    //                                                                                   '#fff7b0',
    //                                                                           }}
    //                                                                       >
    //                                                                           {
    //                                                                               props
    //                                                                                   .progressNote[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                       </div>
    //                                                                       <div>
    //                                                                           {
    //                                                                               props
    //                                                                                   .addendumPN[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                       </div> */}
    //                                                                       {/* <ReactDiffViewer
    //                                                                           oldValue={
    //                                                                               props
    //                                                                                   .progressNote[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           newValue={
    //                                                                               props
    //                                                                                   .addendumPN[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           compareMethod={
    //                                                                               DiffMethod.WORDS
    //                                                                           }
    //                                                                           splitView={
    //                                                                               false
    //                                                                           }
    //                                                                           hideLineNumbers
    //                                                                       /> */}
    //                                                                       <Diff
    //                                                                           inputA={
    //                                                                               props
    //                                                                                   .progressNote[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           inputB={
    //                                                                               props
    //                                                                                   .addendumPN[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           type="words"
    //                                                                       />
    //                                                                       <Divider
    //                                                                           variant="middle"
    //                                                                           className={
    //                                                                               props
    //                                                                                   .styles
    //                                                                                   ?.divider
    //                                                                           }
    //                                                                       />
    //                                                                   </div>
    //                                                               </div>
    //                                                           </div>
    //                                                       )
    //                                                   }
    //                                               },
    //                                           )}
    //                                       </div>
    //                                   )
    //                           } else if (pNotesKeys == 'PHYSICAL EXAM') {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.addendumPN[
    //                                           'PHYSICAL EXAM' as keyof initial2CValuesType
    //                                       ],
    //                                       PE,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>PHYSICAL EXAM:</b>
    //                                           {peKeys.map((peKey, index) => {
    //                                               if (
    //                                                   props.addendumPN[
    //                                                       'PHYSICAL EXAM'
    //                                                   ]![
    //                                                       peKey as keyof physicalExamType
    //                                                   ] == null ||
    //                                                   props.addendumPN[
    //                                                       'PHYSICAL EXAM'
    //                                                   ]![
    //                                                       peKey as keyof physicalExamType
    //                                                   ] != ''
    //                                               ) {
    //                                                   return (
    //                                                       <div
    //                                                           key={
    //                                                               peKey + index
    //                                                           }
    //                                                       >
    //                                                           <div
    //                                                               className={
    //                                                                   props
    //                                                                       .styles
    //                                                                       ?.PNKeys2
    //                                                               }
    //                                                           >
    //                                                               {peKey}:
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNData
    //                                                                   }
    //                                                               >
    //                                                                   {/* <div
    //                                                                       style={{
    //                                                                           backgroundColor:
    //                                                                               '#fff7b0',
    //                                                                       }}
    //                                                                   >
    //                                                                       {
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                   </div>
    //                                                                   <div>
    //                                                                       {
    //                                                                           props
    //                                                                               .addendumPN[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                   </div> */}
    //                                                                   {/* <ReactDiffViewer
    //                                                                       oldValue={
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       newValue={
    //                                                                           props
    //                                                                               .addendumPN[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       compareMethod={
    //                                                                           DiffMethod.WORDS
    //                                                                       }
    //                                                                       splitView={
    //                                                                           false
    //                                                                       }
    //                                                                       hideLineNumbers
    //                                                                   /> */}
    //                                                                   <Diff
    //                                                                       className={
    //                                                                           styles2.Difference
    //                                                                       }
    //                                                                       //   inputA={
    //                                                                       //       'Patient appears uncomfortable, but cooperative. The patient reports an abscess on the Bartholian gland which is causing the discomfort and swelling'
    //                                                                       //   }
    //                                                                       //   inputB={
    //                                                                       //       'Lab culture sent to the lab for further analysis. Antibiotic started as prescribed by the doctor. Follow-up appointment in one week to monitor progress'
    //                                                                       //   }
    //                                                                       inputA={
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       inputB={
    //                                                                           props
    //                                                                               .addendumPN[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       type="words"
    //                                                                   />
    //                                                                   <Divider
    //                                                                       variant="middle"
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.divider
    //                                                                       }
    //                                                                   />
    //                                                               </div>
    //                                                           </div>
    //                                                       </div>
    //                                                   )
    //                                               }
    //                                           })}
    //                                       </div>
    //                                   )
    //                           } else if (
    //                               //   props.addendumPN[
    //                               //       pNotesKeys as keyof typeof props.addendumPN
    //                               //   ] !== '' ||
    //                               props.addendumPN[
    //                                   pNotesKeys as keyof typeof props.addendumPN
    //                               ] != null
    //                           ) {
    //                               //   console.log(
    //                               //       'propslog',
    //                               //       pNotesKeys,
    //                               //       props.addendumPN[
    //                               //           pNotesKeys as keyof typeof props.addendumPN
    //                               //       ] != null,
    //                               //       props.addendumPN[
    //                               //           pNotesKeys as keyof typeof props.addendumPN
    //                               //       ] != '' ||
    //                               //           props.addendumPN[
    //                               //               pNotesKeys as keyof typeof props.addendumPN
    //                               //           ] != null,
    //                               //   )
    //                               return (
    //                                   <div
    //                                       key={pNotesKeys + index}
    //                                       className={props.styles?.ReportKeys}
    //                                   >
    //                                       {pNotesKeys}:
    //                                       <div className={props.styles?.PNData}>
    //                                           {/* <div
    //                                               style={{
    //                                                   backgroundColor:
    //                                                       '#fff7b0',
    //                                               }}
    //                                           >
    //                                               {
    //                                                   props.progressNote[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                           </div>
    //                                           <div>
    //                                               {
    //                                                   props.addendumPN[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                           </div> */}
    //                                           {/* <ReactDiffViewer
    //                                               oldValue={
    //                                                   props.progressNote[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               newValue={
    //                                                   props.addendumPN[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               compareMethod={
    //                                                   DiffMethod.WORDS
    //                                               }
    //                                               splitView={false}
    //                                               hideLineNumbers
    //                                           /> */}
    //                                           <Diff
    //                                               className={styles2.Difference}
    //                                               inputA={
    //                                                   props.progressNote[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               inputB={
    //                                                   props.addendumPN[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               type="words"
    //                                           />
    //                                           <Divider
    //                                               variant="middle"
    //                                               className={
    //                                                   props.styles?.divider
    //                                               }
    //                                           />
    //                                       </div>
    //                                   </div>
    //                               )
    //                           }
    //                       },
    //                   )
    //                 : Object.keys(props.progressNote).map(
    //                       (pNotesKeys: any, index: number) => {
    //                           if (pNotesKeys == 'REVIEW OF SYSTEMS') {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.progressNote[
    //                                           'REVIEW OF SYSTEMS' as keyof initial2CValuesType
    //                                       ],
    //                                       review,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>REVIEW OF SYSTEMS:</b>
    //                                           {rosKeys.map(
    //                                               (
    //                                                   keys: string,
    //                                                   index: number,
    //                                               ) => {
    //                                                   if (
    //                                                       props.addendumPN?.[
    //                                                           'REVIEW OF SYSTEMS'
    //                                                       ]![
    //                                                           keys as keyof reviewOfSystemType
    //                                                       ]
    //                                                   ) {
    //                                                       return (
    //                                                           <div
    //                                                               key={
    //                                                                   keys +
    //                                                                   index
    //                                                               }
    //                                                           >
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNKeys2
    //                                                                   }
    //                                                               >
    //                                                                   {keys}:
    //                                                                   <div
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.PNData
    //                                                                       }
    //                                                                   >
    //                                                                       {/* <ReactDiffViewer
    //                                                                           oldValue={
    //                                                                               props
    //                                                                                   .progressNote[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           newValue={
    //                                                                               props
    //                                                                                   .addendumPN[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           compareMethod={
    //                                                                               DiffMethod.WORDS
    //                                                                           }
    //                                                                           splitView={
    //                                                                               false
    //                                                                           }
    //                                                                           hideLineNumbers
    //                                                                       /> */}
    //                                                                       <Diff
    //                                                                           inputA={
    //                                                                               props
    //                                                                                   .progressNote[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           inputB={
    //                                                                               props
    //                                                                                   .addendumPN[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                           type="words"
    //                                                                       />
    //                                                                       {/* <div
    //                                                                           style={{
    //                                                                               backgroundColor:
    //                                                                                   '#fff7b0',
    //                                                                           }}
    //                                                                       >
    //                                                                           {
    //                                                                               props
    //                                                                                   .progressNote[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                       </div>
    //                                                                       <div>
    //                                                                           {
    //                                                                               props
    //                                                                                   .addendumPN[
    //                                                                                   'REVIEW OF SYSTEMS'
    //                                                                               ]![
    //                                                                                   keys as keyof reviewOfSystemType
    //                                                                               ]
    //                                                                           }
    //                                                                       </div> */}
    //                                                                       <Divider
    //                                                                           variant="middle"
    //                                                                           className={
    //                                                                               props
    //                                                                                   .styles
    //                                                                                   ?.divider
    //                                                                           }
    //                                                                       />
    //                                                                   </div>
    //                                                               </div>
    //                                                           </div>
    //                                                       )
    //                                                   }
    //                                               },
    //                                           )}
    //                                       </div>
    //                                   )
    //                           } else if (pNotesKeys == 'PHYSICAL EXAM') {
    //                               if (
    //                                   !_.isEqual(
    //                                       props.addendumPN?.[
    //                                           'PHYSICAL EXAM' as keyof initial2CValuesType
    //                                       ],
    //                                       PE,
    //                                   )
    //                               )
    //                                   return (
    //                                       <div key={pNotesKeys + index}>
    //                                           <b>PHYSICAL EXAM:</b>
    //                                           {peKeys.map((peKey, index) => {
    //                                               if (
    //                                                   props.addendumPN?.[
    //                                                       'PHYSICAL EXAM'
    //                                                   ]![
    //                                                       peKey as keyof physicalExamType
    //                                                   ]
    //                                               ) {
    //                                                   return (
    //                                                       <div
    //                                                           key={
    //                                                               peKey + index
    //                                                           }
    //                                                       >
    //                                                           <div
    //                                                               className={
    //                                                                   props
    //                                                                       .styles
    //                                                                       ?.PNKeys2
    //                                                               }
    //                                                           >
    //                                                               {peKey}:
    //                                                               <div
    //                                                                   className={
    //                                                                       props
    //                                                                           .styles
    //                                                                           ?.PNData
    //                                                                   }
    //                                                               >
    //                                                                   {/* <ReactDiffViewer
    //                                                                       oldValue={
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       newValue={
    //                                                                           props
    //                                                                               .addendumPN?.[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       compareMethod={
    //                                                                           DiffMethod.WORDS
    //                                                                       }
    //                                                                       splitView={
    //                                                                           false
    //                                                                       }
    //                                                                       hideLineNumbers
    //                                                                   /> */}
    //                                                                   <Diff
    //                                                                       className={
    //                                                                           styles2.Difference
    //                                                                       }
    //                                                                       inputA={
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       inputB={
    //                                                                           //   props
    //                                                                           //       .progressNote[
    //                                                                           //       'PHYSICAL EXAM'
    //                                                                           //   ]![
    //                                                                           //       peKey as keyof physicalExamType
    //                                                                           //   ] +
    //                                                                           //   ' ' +
    //                                                                           props
    //                                                                               .addendumPN?.[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                       type="words"
    //                                                                   />
    //                                                                   {/* <div
    //                                                                       style={{
    //                                                                           backgroundColor:
    //                                                                               '#fff7b0',
    //                                                                       }}
    //                                                                   >
    //                                                                       {
    //                                                                           props
    //                                                                               .progressNote[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]
    //                                                                       }
    //                                                                   </div>
    //                                                                   <div
    //                                                                   //   style={{
    //                                                                   //       backgroundColor:
    //                                                                   //           '#fff7b0',
    //                                                                   //   }}
    //                                                                   >
    //                                                                       {props
    //                                                                           .progressNote[
    //                                                                           'PHYSICAL EXAM'
    //                                                                       ]![
    //                                                                           peKey as keyof physicalExamType
    //                                                                       ] +
    //                                                                           ' ' +
    //                                                                           props
    //                                                                               .addendumPN?.[
    //                                                                               'PHYSICAL EXAM'
    //                                                                           ]![
    //                                                                               peKey as keyof physicalExamType
    //                                                                           ]}
    //                                                                   </div> */}
    //                                                                   <Divider
    //                                                                       variant="middle"
    //                                                                       className={
    //                                                                           props
    //                                                                               .styles
    //                                                                               ?.divider
    //                                                                       }
    //                                                                   />
    //                                                               </div>
    //                                                           </div>
    //                                                       </div>
    //                                                   )
    //                                               }
    //                                           })}
    //                                       </div>
    //                                   )
    //                           } else if (
    //                               //   props.addendumPN[
    //                               //       pNotesKeys as keyof typeof props.addendumPN
    //                               //   ] !== '' ||
    //                               props.addendumPN?.[
    //                                   pNotesKeys as keyof typeof props.addendumPN
    //                               ] != null
    //                           ) {
    //                               return (
    //                                   <div
    //                                       key={pNotesKeys + index}
    //                                       className={props.styles?.ReportKeys}
    //                                   >
    //                                       {pNotesKeys}:
    //                                       <div className={props.styles?.PNData}>
    //                                           {/* <div
    //                                               style={{
    //                                                   backgroundColor:
    //                                                       '#fff7b0',
    //                                               }}
    //                                           >
    //                                               {
    //                                                   props.progressNote[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                           </div>
    //                                           <div
    //                                           //   style={{
    //                                           //       backgroundColor:
    //                                           //           '#fff7b0',
    //                                           //   }}
    //                                           >
    //                                               {props.progressNote[
    //                                                   pNotesKeys as keyof typeof props.progressNote
    //                                               ] +
    //                                                   ' ' +
    //                                                   props.addendumPN[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]}
    //                                           </div> */}
    //                                           {/* <ReactDiffViewer
    //                                               oldValue={
    //                                                   props.progressNote[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               newValue={
    //                                                   props.addendumPN[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               compareMethod={
    //                                                   DiffMethod.WORDS
    //                                               }
    //                                               splitView={false}
    //                                               hideLineNumbers
    //                                           /> */}
    //                                           <Diff
    //                                               className={styles2.Difference}
    //                                               inputA={
    //                                                   props.progressNote[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               inputB={
    //                                                   //   props.progressNote[
    //                                                   //       pNotesKeys as keyof typeof props.progressNote
    //                                                   //   ] +
    //                                                   //   ' ' +
    //                                                   props.addendumPN[
    //                                                       pNotesKeys as keyof typeof props.progressNote
    //                                                   ]
    //                                               }
    //                                               type="words"
    //                                           />
    //                                           <Divider
    //                                               variant="middle"
    //                                               className={
    //                                                   props.styles?.divider
    //                                               }
    //                                           />
    //                                       </div>
    //                                   </div>
    //                               )
    //                           }
    //                       },
    //                   )}
    //         </div>
    //     )),
    // }
}

export default ViewProgressNote
