import React from "react";
import dayjs, { Dayjs } from 'dayjs'
import { ViewTaskProps } from "./viewTaskService";
import { getAuditors, getManagers, updateTask } from "../api/taskAPIService";
import { validate } from './common';
import { AuditorlistProps } from "../types/taskmanagertypes";
import { LoaderGFContext } from "../../Components/Loader/LoaderProvider";
import { SnackbarContext } from "../../Components/Snackbar/SnackbarProvider";
import { editTaskReducer, initiateState } from "../reducer/edittaskReducer";
import moment from "moment";

export function useEditTask(props: ViewTaskProps) {
    const { snackbarSetting } = React.useContext(SnackbarContext);
    const { loaderSetting } = React.useContext(LoaderGFContext);
    const [states, setStates] = React.useReducer(editTaskReducer, initiateState);

    const [error, setError] = React.useState({
        auditorIdError: false,
        orgTidError: false,
        providerIdError: false,
        patientIdError: false,
        specialityError: false,
        taskNameError: false
    });
    const [auditorList, setAuditorList] = React.useState<AuditorlistProps[]>([]);
    const [showView, setView] = React.useState(false);
    const [tableValue, setTableValue] = React.useState(0);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTableValue(newValue);
    };

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        validate(e, setError, error);
        setStates({ type: "add", field: [e.target.name], payload: e.target.value.replace(/[^a-zA-Z0-9 ]/g, "").replace(/(\*|[\uE000-\uF8FF]|\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])/g, "") })
    };
    const handleorgChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        validate(e, setError, error);
        setStates({ type: "add", field: [e.target.name], payload: e.target.value.replace(/\D/g, '') })
    };
    const handleStartChange = (newValue: Dayjs | null) => {
        const selected = newValue?.format('YYYY-MM-DD');
        const maxdate = moment(states?.endDate).format('YYYY-MM-DD');
        if (newValue && selected && (new Date(selected) <= new Date(maxdate))) {
            setStates({ type: "add", field: "startDate", payload: newValue })
        } else {
            snackbarSetting(true, "Start date must be lesser than end date!", "success")
        }
    }
    const handleEndChange = (newValue: Dayjs | null) => {
        const selected = newValue?.format('YYYY-MM-DD');
        const maxdate = moment(states?.startDate).format('YYYY-MM-DD');
        if (newValue && selected && (new Date(selected) >= new Date(maxdate))) {
            setStates({ type: "add", field: "endDate", payload: newValue })
        } else {
            snackbarSetting(true, "End date must be greater than start date!", "success")
        }
    }

    const handleDropChange = (value: string, name: string) => {
        setStates({ type: "add", field: [name], payload: value })
    }
    const handleSubmit = async () => {
        loaderSetting(true)
        await updateTask(states).then(async (res: any) => {
            if (res?.status) {
                if (res?.task && props.updatedTaskList) {
                    props.updatedTaskList(res?.task);
                    upadateState(res?.task, true);
                }
                loaderSetting(false)
                snackbarSetting(true, res?.message, "success")
            } else {
                loaderSetting(false);
                snackbarSetting(true, res?.message, "error")
            }
        });
    }
    const toggleEdit = () => {
        setView(!showView);
    }
    const upadateState = (task: any, flag: boolean) => {
        Object.keys(task).forEach((item: any) => {
            setStates({ type: "add", field: item, payload: task[item] })
        });
        if (flag) {
            setView(true);
        } else {
            setStates({ type: "add", field: "endDate", payload: dayjs(task?.endDate) });
            setStates({ type: "add", field: "startDate", payload: dayjs(task?.startDate) });
            setStates({ type: "add", field: "patientdob", payload: dayjs(task?.patientdob) });
        }
    }
    React.useEffect(() => {
        async function initCall() {
            if (props?.task) {
                const task: any = props?.task
                upadateState(task, false)
            }
            const auditorsRes = await getAuditors()
            let alist: any[] = []
            if (auditorsRes) {
                // setAuditorList(auditorsRes.users)
                alist = alist.concat(auditorsRes.users)
            }
            const managersRes = await getManagers()
            for(const i in managersRes.users){
                managersRes.users[i].lastName = managersRes.users[i].lastName + " - manager"
            }
            for(const i in managersRes.admins){
                managersRes.admins[i].lastName = managersRes.admins[i].lastName + " - admin"
            }
            if (managersRes) {
                alist = alist.concat(managersRes.users)
                alist = alist.concat(managersRes.admins)
            }
            setAuditorList(alist)
        }
        initCall();
    }, []);

    function checkAllField() {
        if (states.taskName.replace(/\s/g, '').length !== 0 && states.orgTid.length !== 0 && states.taskName.replace(/\s/g, '').length !== 0 && states.speciality !== '' && states.auditorId !== '' && states.orgTid !== '' && states.providerId !== '' && states.providerId !== '' && states.speciality !== '') {
            return false;
        } else {
            return true;
        }
    }

    return { handleorgChange, toggleEdit, auditorList, handleTabChange, tableValue, showView, error, checkAllField, handleChange, handleSubmit, handleDropChange, handleEndChange, handleStartChange, states };
}